import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, switchMap, filter } from 'rxjs/operators';
import * as fromApp from '../../ngrx/app.reducers';
import { onceIfNotEmpty } from 'src/app/ngrx/util';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private store: Store<fromApp.AppState>,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(fromApp.isAuthenticated)
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        switchMap(() => {
          return this.store.select(fromApp.getIsAdmin);
        }),
        onceIfNotEmpty(),
        map(isAdmin => {
          if (isAdmin) {
            return true;
          } else {
            this.router.navigate(["/403"]);
            return false;
          }
        })
      )
  }
}