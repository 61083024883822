/*
 * Guard per il componente di Login. Il servizio verifica dallo State, unica fonte di verità, se l'utente è correttamente autenticato e, in questo caso,
 * salta la procedura di Login andando nella pagina di default, l'home page.
*/

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, map, tap } from 'rxjs/operators';
import * as fromApp from '../../ngrx/app.reducers';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { TranslateService } from '@ngx-translate/core';
import * as CoreActions from '../../core/ngrx/core.actions';

@Injectable()
export class PrivateNoteGuard implements CanActivate {
    constructor(
        private store: Store<fromApp.AppState>,
        private translate: TranslateService) {
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.select(fromApp.getShowImpesonificaitonBanner)
            .pipe(take(1),
                map(isImpersonate => {
                    if (isImpersonate) {
                        const messageObj: ApplicationModalMessage = {
                            modalId: "err042",
                            text: this.translate.instant("errors.DENIED_NOTES_DESCR"),
                            title: this.translate.instant("errors.DENIED_NOTES_TITLE")
                        }
                        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                        return false;
                    } else {
                        return true;
                    }
                })
            )
    }
}