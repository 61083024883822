import { NgModule, Injectable } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared/shared.module';
import { UrlService } from '../shared/services/url.service';
import { RedirectService } from '../shared/services/redirect.service';
import { AuthService } from '../auth/services/auth.service';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { CompatibilityGuard } from '../shared/services/compatibility-guard.service';
import { LoginGuard } from '../shared/services/login-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CompatibilityErrorComponent } from './compatibility-error/compatibility-error.component';
import { LangsService } from './services/langs.service';
import { AppTranslateModule } from '../translate/translate.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { OwlDateTimeIntl, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlMomentDateTimeModule } from '@busacca/ng-pick-datetime';
import { PageNotAuthorizedComponent } from './page-not-authorized/page-not-authorized.component';
import { AdminGuard } from '../shared/services/admin-guard.service';
import { SelfAssessmentGuard } from '../shared/services/selfAssessment-guard.service';
import { PeopleAppraisalGuard } from '../shared/services/peopleAppraisal-guard.service';
import { ProfileComponent } from './profile/profile.component';
import { DefaultPageComponent } from './defaultPage/default-page.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ImpersonateRedirectHomeComponent } from '../impersonate-home-redirect/impersonate-home-redirect.component';
import { SupportComponent } from './support/support.component';
import { SharedService } from '../shared/services/shared.service';
import { OnBoardingGuard } from '../shared/services/onboarding-guard.service';

export const MY_NATIVE_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'DD/MM/YYYY hh:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'MMMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
  upSecondLabel = 'Aggiungi un secondo';
  downSecondLabel = 'Un secondo in meno';
  upMinuteLabel = 'Aggiungi un minuto';
  downMinuteLabel = 'Un minuto in meno';
  upHourLabel = 'Aggiungi un\'ora';
  downHourLabel = 'Un\'ora in meno';
  prevMonthLabel = 'Mese precedente';
  nextMonthLabel = 'Prossimo mese';
  prevYearLabel = 'Anno precedente';
  nextYearLabel = 'Prossimo anno';
  prevMultiYearLabel = '21 anni precedenti';
  nextMultiYearLabel = 'Prossimi 21 anni';
  switchToMonthViewLabel = 'Cambia visuale';
  switchToMultiYearViewLabel = 'Scegli mese e anno';
  cancelBtnLabel = 'Annulla';
  setBtnLabel = 'Conferma';
  rangeFromLabel = 'Da';
  rangeToLabel = 'A';
  hour12AMLabel = 'AM';
  hour12PMLabel = 'PM';
}

@NgModule({
  declarations: [
    HeaderComponent,
    PageNotFoundComponent,
    PageNotAuthorizedComponent,
    CompatibilityErrorComponent,
    GenericErrorComponent,
    NotificationsComponent,
    ProfileComponent,
    DefaultPageComponent,
    ImpersonateRedirectHomeComponent,
    SupportComponent
  ],
  imports: [
    SharedModule,
    AppTranslateModule,
    NgxPaginationModule,
    OwlMomentDateTimeModule
  ],
  exports: [
    HeaderComponent,
    OwlMomentDateTimeModule
  ],
  providers: [
    UrlService,
    RedirectService,
    AuthService,
    AuthGuard,
    CompatibilityGuard,
    LoginGuard,
    AdminGuard,
    SelfAssessmentGuard,
    PeopleAppraisalGuard,
    LangsService,
    SharedService,
    OnBoardingGuard,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'it' },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl }
  ]
})
export class CoreModule { }
