<div class="box-container" (click)="emitOnButtonClicked()" [ngClass]="{'is-new': isNew}">
  <ng-container *ngIf="!iconBox && imgPath && imgPath.length">
    <div class="img-content" style='background-image:url({{imgPath}})'></div>
  </ng-container>
  <ng-container *ngIf="iconBox && iconSrc && iconSrc.length">
    <div class="img-content icon">
      <svg-icon [src]="iconSrc" class="box-icon" [applyClass]="true"></svg-icon>
    </div>
  </ng-container>
  <div class="info-container">
    <div class="info">
      <p class="title-text">{{title}}</p>
      <p class="description">{{description | translate}}</p>
    </div>
    <div class="new" *ngIf="isNew">
      <p translate="generic.NOVITY"></p>
    </div>
  </div>
</div>