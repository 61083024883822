import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromProfile from '../core/profile/ngrx/profile.reducers';
import * as fromAuth from '../auth/ngrx/auth.reducers';
import * as fromCore from '../core/ngrx/core.reducers';
import * as fromPeopleAppraisal from '../peopleAppraisal/ngrx/peopleAppraisal.reducers';
import * as fromAdmin from '../admin/ngrx/admin.reducers';

export interface AppState {
  user: fromProfile.ProfileState;
  auth: fromAuth.AuthState;
  core: fromCore.CoreState;
  peopleAppraisal: fromPeopleAppraisal.PeopleAppraisalState;
  admin: fromAdmin.AdminState;
}

export const reducers: ActionReducerMap<AppState> = {
  user: fromProfile.profileReducer,
  auth: fromAuth.authReducer,
  core: fromCore.coreReducer,
  peopleAppraisal: fromPeopleAppraisal.peopleAppraisalReducer,
  admin: fromAdmin.adminReducer
};

export const getAuthAppState = createFeatureSelector<fromAuth.AuthState>('auth');
export const getCoreAppState = createFeatureSelector<fromCore.CoreState>('core');
export const getProfileAppState = createFeatureSelector<fromProfile.ProfileState>('user');
export const getPeopleAppraisalAppState = createFeatureSelector<fromPeopleAppraisal.PeopleAppraisalState>('peopleAppraisal');
export const getAdminAppState = createFeatureSelector<fromAdmin.AdminState>('admin');

export const getAuthState = createSelector(
  getAuthAppState,
  (state: fromAuth.AuthState) => state
);
export const getToken = createSelector(
  getAuthState,
  fromAuth.getToken
);
export const getUserAcknowledges = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.userAcknowledges
);

// PeopleAppraisal
export const getPeopleAppraisalState = createSelector(
  getPeopleAppraisalAppState,
  (state: fromPeopleAppraisal.PeopleAppraisalState) => state
);
export const getSubordinatesWithPhaseStatuses = createSelector(
  getPeopleAppraisalAppState,
  (state: fromPeopleAppraisal.PeopleAppraisalState) => state.subordinatesWithPhaseStatuses
);

// Selettori relativi allo state del core
export const getCoreState = createSelector(
  getCoreAppState,
  (state: fromCore.CoreState) => state
);
export const isAuthenticated = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.authenticated
);
export const isFetchingLangs = createSelector(
  getCoreAppState,
  fromCore.isFetchingLangs
);
export const getApplicationModalMessage = createSelector(
  getCoreAppState,
  fromCore.getApplicationModalMessage
);
export const getApplicationModalBadge = createSelector(
  getCoreAppState,
  fromCore.getApplicationModalBadge
);
export const getShowImpesonificaitonBanner = createSelector(
  getCoreAppState,
  fromCore.getShowImpesonificaitonBanner
);
export const getRunningYear = createSelector(
  getCoreAppState,
  fromCore.getRunningYear
);
export const getRunningPhase = createSelector(
  getCoreAppState,
  fromCore.getRunningPhase
);
export const getRedirectUrl = createSelector(
  getCoreAppState,
  fromCore.getRedirectUrl
);
export const getApplicationContext = createSelector(
  getCoreAppState,
  fromCore.getApplicationContext
);
export const getGlobalApplicationData = createSelector(
  getCoreAppState,
  fromCore.getGlobalApplicationData
);
export const showApplicationLoader = createSelector(
  getCoreAppState,
  fromCore.showApplicationLoader
);
export const isMainMenuSidebarOpened = createSelector(
  getCoreAppState,
  fromCore.isMainMenuSidebarOpened
);
export const getApplicationLang = createSelector(
  getCoreAppState,
  fromCore.getApplicationLang
);
export const getDefaultLang = createSelector(
  getCoreAppState,
  fromCore.getDefaultLang
);

export const getProfileState = createSelector(
  getProfileAppState,
  (state: fromProfile.ProfileState) => state
);
export const getLoggedUser = createSelector(
  getProfileState,
  fromProfile.getLoggedUser
);
export const getIsPeopleAppraisal = createSelector(
  getProfileState,
  fromProfile.getIsPeopleAppraisal
);
export const getIsSelfAssessment = createSelector(
  getProfileState,
  fromProfile.getIsSelfAssessment
);
export const getIsAdmin = createSelector(
  getProfileState,
  fromProfile.getIsAdmin
);
export const getIsStaff = createSelector(
  getProfileState,
  fromProfile.getIsStaff
);
export const getIsOperation = createSelector(
  getProfileState,
  fromProfile.getIsOperation
);
export const getIsSti = createSelector(
  getProfileState,
  fromProfile.getIsSti
);
export const getIsChief = createSelector(
  getProfileState,
  fromProfile.getIsChief
);
export const getPeerFeedbackAllowed = createSelector(
  getProfileState,
  fromProfile.getPeerFeedbackAllowed
);
export const getAvailableLangs = createSelector(
  getProfileState,
  fromProfile.getAvailableLangs
);
export const getNotificationsCounter = createSelector(
  getCoreAppState,
  fromCore.getNotificationsCounter
);
export const getNotificationsInitials = createSelector(
  getCoreAppState,
  fromCore.getNotificationsInitials
);

// PeopleAppraisalState
export const getActiveUserId = createSelector(
  getPeopleAppraisalAppState,
  fromPeopleAppraisal.getActiveUserId
);

export const getIsButtonTeamClicked = createSelector(
  getPeopleAppraisalAppState,
  fromPeopleAppraisal.getIsButtonTeamClicked
);

export const getIsLoadingSubordinates = createSelector(
  getPeopleAppraisalAppState,
  fromPeopleAppraisal.getIsLoadingSubordinates
);

// AdminState
export const getMatrixData = createSelector(
  getAdminAppState,
  fromAdmin.getMatrixData
)

export const getFiltersText = createSelector(
  getAdminAppState,
  fromAdmin.getFiltersText
)