/*
 * Componente per l'errore 404, pagina richiesta non trovata
*/

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import * as fromApp from '../../ngrx/app.reducers';

@Component({
  selector: 'generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent {
  errorCode: string = '';
  isImpersonate: boolean = false;

  constructor(
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    public route: ActivatedRoute
  ) {
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })
    this.route.params
      .subscribe((params: any) => {
        if (params.errorCode) {
          this.errorCode = params.errorCode;
        }
      })
  }

  goToHome() {
    this.redirectService.goToHome()
  }
}
