import { Component, Input, OnInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";

@Component({
  selector: 'box-home',
  templateUrl: 'box-home.component.html',
  styleUrls: ['./box-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxHomeComponent implements OnInit {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() isNew: boolean = false;
  @Input() attrAriaLabel: string = '';
  @Input() imgPath: string = '';
  @Input() iconBox: boolean = false;
  @Input() iconSrc: string = '';
  @Input() backgroundColor: string = '';
  @Input() isDisabled: boolean = false;
  @Input() disabledModalMessage?: ApplicationModalMessage;
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    this.imgPath = this.imgPath || "../../../../assets/img/icons/homeIcons/placeholder-img.png";
  }

  emitOnButtonClicked(data?: any) {
    if (this.isDisabled) {
      if (this.disabledModalMessage) {
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.disabledModalMessage }));
      }
    } else {
      this.onButtonClicked.emit(data);
    }
  }
}